<template>
    <div class="flex flex-col relative rounded-lg shadow p-5 bg-white dark:bg-gray-750">
        <template v-if="loading">
            <div class="absolute top-0 left-0 w-full h-full z-40 backdrop-blur-sm flex items-center justify-center">
                <Loading />
            </div>
        </template>
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div>
                    <h2 class="font-semibold text-2xl dark:text-gray-300 mb-3">{{component.display_name}}</h2>
                </div>
                <div class="shadow overflow-hidden sm:rounded-lg">
                    <template v-if="data">
                        <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                            <thead class="bg-gray-50 dark:bg-gray-800 dark:bg-opacity-50">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider"></th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">Šodien</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">Vakar</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">Šomēnes</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 dark:divide-gray-600">
                                <template v-for="(item, index) in data" :key="`${index}_${item.name}`">
                                    <tr class="">
                                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-200">{{item.name}}</td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-200">{{item.today}}</td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-200">{{item.yesterday}}</td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-200">{{item.this_month}}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

import { defineAsyncComponent } from 'vue'

const Loading = defineAsyncComponent(() =>
  import('@/components/Components/Loading'))

export default {
    name: "UserProductionsCountWidget",
    components: {
        Loading,
    },
    props: {
        component: {
            type: Object
        }
    },
    data() {
        return {
            data: null,
            loading: false,
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            axios
            .get(baseUrl + "/api/dashboard_components/" + this.component.id)
            .then((response) => {
                this.data = response.data
                this.loading = false
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.$store.dispatch("destroyToken");
                }
                this.loading = false
            });
        },
    }
}
</script>

<style>

</style>